<template>
  <div>
    <FullTable
      :other-filter-props="{
        add: true,
        search: true,
      }"
      :items="GET_GROUPS_LIST.results"
      :fields="fields"
      :rows="GET_GROUPS_LIST.count"
      :detail-btn="false"
      :first-params="params"
      @edit-button-clicked="editModal"
      @delete-button-clicked="deleteModalGroups"
      @refresh="FETCH_GROUPS_LIST"
      @add-button-clicked="add"
    />

    <DefoultAddModal
      id="addEditModal"
      edit-title="Редактировать группы"
      add-title="Добавить группы"
      :no-lang="true"
      :is-edit="isEdit"
      :edit-data="editData"
      @add-button="saveGroup"
    />
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'
import FullTable from '@/components/FullTable.vue'
import DefoultAddModal from '@/components/DefoultAddModal.vue'
import toast from '@/mixins/toast'
import areYouSure from '@/mixins/areYouSure'

export default {
  components: {
    FullTable,
    DefoultAddModal,
  },
  mixins: [toast, areYouSure],
  data() {
    return {
      params: {
        page_size: 10,
        page: 1,
        ordering: null,
        search: null,
      },
      editData: {},
      isEdit: false,
      fields: [
        {
          label: '№',
          key: 'id',
        },
        {
          label: 'Названия',
          key: 'title',
        },
        {
          label: 'Действия',
          key: 'actions',
        },
      ],
    }
  },
  computed: {
    ...mapGetters('knowledgeBase', ['GET_GROUPS_LIST']),
  },
  methods: {
    ...mapActions('knowledgeBase', ['FETCH_GROUPS_LIST', 'CREATE_GROUPS_LIST', 'DELETE_GROUPS_LIST', 'UPDATE_GROUPS']),

    saveGroup(data) {
      if (this.isEdit) {
        this.UPDATE_GROUPS(data)
          .then(() => {
            this.$_successToast('Успешно Редектировано')
            this.$nextTick(() => {
              this.$bvModal.hide('addEditModal')
              this.FETCH_GROUPS_LIST(this.params)
            })
          })
          .catch(err => {
            this.$_errorToast(err)
          })
      } else {
        this.CREATE_GROUPS_LIST(data)
          .then(() => {
            this.$_successToast('Успешно Добавлено')
            this.$nextTick(() => {
              this.$bvModal.hide('addEditModal')
              this.FETCH_GROUPS_LIST(this.params)
            })
          })
          .catch(() => {
            this.$_errorToast('Ошибка')
          })
      }
    },

    deleteModalGroups(id) {
      this.$_showAreYouSureModal()
        .then(value => {
          if (value) {
            this.DELETE_GROUPS_LIST(id)
              .then(() => {
                this.$_successToast('Успешно удалено')
                this.FETCH_GROUPS_LIST(this.params)
              })
              .catch(() => {
                this.$_errorToast('Ошибка')
              })
          }
        })
    },

    add() {
      this.isEdit = false
      this.editData = {
        title: '',
      }
      this.$nextTick(() => {
        this.$bvModal.show('addEditModal')
      })
    },

    editModal(data) {
      this.isEdit = true
      this.editData = data
      this.$nextTick(() => {
        this.$bvModal.show('addEditModal')
      })
    },
  },
}
</script>
